<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-if="isadmin"
                            v-model="filter.network"
                            :items='networkNames'
                            item-text="name"
                            item-value="uuid"
                            :label='$t("Red")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            deletable-chips
                        )
                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label="$t('Desde')" v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label="$t('Hasta')" v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="leaderColor"
                v-model="leaderMsj")
                    | {{ leaderText }}
                    v-btn(flat, dark, @click.native="leaderMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Líderes')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap v-if="leader.length")
                            v-flex(xs12 sm6 md4 lg3 xl2 v-for="leader in leader" :key="leader.uuid")
                                .sheet.grey.lighten-3
                                    .sheet-options
                                        v-menu(left)
                                            v-icon(color='primary' dark slot='activator') more_vert
                                            v-list.sheet-menu
                                                v-list-tile.white--text(@click="open('edit', $t('Editar líder'), leader)")
                                                    v-list-tile-title {{$t('Editar')}}
                                                v-list-tile.white--text(@click="$refs.delete.open(leader)")
                                                    v-list-tile-title {{$t('Eliminar')}}
                                    .sheet-title
                                        | {{$t('Red')}}: {{ leader.network.name }}
                                    .sheet-content
                                        p {{$t('Líder')}}: {{ leader.user.names }}
                            v-flex(xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{$t('No se encontraron lideres')}}
                    v-btn(@click.native="open('add', $t('Agregar líder'))" fixed dark fab bottom right color='primary')
                        v-icon add
                    v-dialog(v-model="leaderModal" max-width="600")
                        v-form
                            v-card
                                v-card-title {{$t('Crear o editar líder')}}
                                v-card-text
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                v-model="leaderModel.networkid"
                                                :items='networkNames'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Red")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                @input="leaderModel.users=[],leaderModel.userid=''"
                                            )
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                :loading="loading1"
                                                v-model="leaderModel.userid"
                                                :items='users1'
                                                item-text="email"
                                                item-value="uuid"
                                                :label='$t("Líder")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilterEmail"
                                                :search-input.sync="search1"
                                            )
                                                template(slot="selection" slot-scope="data")
                                                    div.input-group__selections__comma {{ data.item.names }} - {{  data.item.email }}
                                                template(slot="item" slot-scope="data")
                                                    v-list-tile-content
                                                        v-list-tile-title {{ data.item.names }}
                                                        v-list-tile-sub-title {{ data.item.email }}
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            h3.mb-3 {{$t('Usuarios')}}
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                :loading="loading2"
                                                v-model="leaderModel.users"
                                                :items='users2'
                                                item-text="email"
                                                item-value="uuid"
                                                :label='$t("Usuarios")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilterEmail"
                                                :search-input.sync="search2"
                                                chips
                                                multiple
                                                deletable-chips
                                                :return-object="true"
                                            )
                                                template(slot="item" slot-scope="data")
                                                    v-list-tile-content
                                                        v-list-tile-title {{ data.item.names }}
                                                        v-list-tile-sub-title {{ data.item.email }}
                                        v-flex(xs12 v-if="leaderModel.users")
                                            v-data-table(
                                                :headers='leaderUserHeaders'
                                                :items='leaderModel.users'
                                                hide-actions
                                            )
                                                template(slot='headers' slot-scope='props')
                                                    tr
                                                        th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                            span.subheading {{ $t(header.text) }}
                                                template(slot='items' slot-scope='props')
                                                    tr
                                                        td(align="left") {{ props.item.names }}
                                                        td(align="left") {{ props.item.email }}
                                                        td(align="left")
                                                            small {{$t('Usuario y sus predecesores')}}
                                                            .switch-group(style="font-size:12px;")
                                                                label {{$t('No')}}
                                                                v-switch(:label="$t('Si')", color="success", v-model="props.item.recursive")
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            h3.mb-3 {{$t('Usuarios excluidos')}}
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                :loading="loading3"
                                                v-model="leaderModel.excludes"
                                                :items='users3'
                                                item-text="email"
                                                item-value="uuid"
                                                :label='$t("Usuarios excluidos")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilterEmail"
                                                :search-input.sync="search3"
                                                chips
                                                multiple
                                                deletable-chips
                                            )
                                                template(slot="item" slot-scope="data")
                                                    v-list-tile-content
                                                        v-list-tile-title {{ data.item.names }}
                                                        v-list-tile-sub-title {{ data.item.email }}
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            h3.mb-3 {{$t('Grupo de usuarios')}}
                                        v-flex(xs12 sm6)
                                            v-select.mb-3(
                                                v-model="modelRule.country"
                                                :items='countries'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("País")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                :return-object="true"
                                                @input="modelRule.state={},modelRule.city={},states=[],cities=[],fetchState()"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select.mb-3(
                                                v-model="modelRule.state"
                                                :items='states'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Estado/Departamento")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                :return-object="true"
                                                @input="modelRule.city={},cities=[],fetchCity()"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select.mb-3(
                                                v-model="modelRule.city"
                                                :items='cities'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Ciudad")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                :return-object="true"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select(
                                                v-model="modelRule.gender"
                                                :items='gender'
                                                item-text="text"
                                                item-value="id"
                                                :label='$t("Género")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :return-object="true"
                                                :filter="vFilter"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select(
                                                v-model="modelRule.age"
                                                :items='ages'
                                                item-text="text"
                                                item-value="id"
                                                :label='$t("Rango de edad")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :return-object="true"
                                                :filter="vFilter"
                                            )
                                        v-flex(xs12)
                                            .btns.btns-right
                                                v-btn(color="secondary" small @click="addRule") {{ $t('Agregar condición') }}
                                        v-flex.network_rule(xs12)
                                            v-list
                                                v-list-tile(v-for="(rule, i) in modelRule.rules" :key="'networkr'+i")
                                                    v-list-tile-content 
                                                        small
                                                            template(v-if="rule.country && Object.keys(rule.country).length") {{ $t('País') }}: {{ rule.country.name }}&nbsp;
                                                            template(v-if="rule.state && Object.keys(rule.state).length") {{ $t('Estado/departamento') }}: {{ rule.state.name }}&nbsp;
                                                            template(v-if="rule.city && Object.keys(rule.city).length") {{ $t('Ciudad') }}: {{ rule.city.name }}&nbsp;
                                                            template(v-if="rule.gender && Object.keys(rule.gender).length") {{ $t('Género') }}: {{ rule.gender.text }}&nbsp;
                                                            template(v-if="rule.age && Object.keys(rule.age).length") {{ $t('Rango de edad') }}: {{ rule.age.text }}&nbsp;

                                                    v-list-tile-action
                                                        v-icon(@click="modelRule.rules.splice(i, 1)") delete
                                v-card-actions
                                    .btns.btns-right
                                        v-btn(color="secondary" small @click="leaderModal = false") {{$t('Cancelar')}}
                                        v-btn(:loading="loadingBtn" color="primary" large @click.stop="save") {{$t('Guardar')}}
            confirm(ref="delete" @action="deleteLeader" :title="$t('Eliminar líder')" :message="$t('¿Estás seguro de eliminar este líder?')")
</template>

<script>

    import auth from 'mixins/auth'
    
    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Líderes')
            }
        },
        data() {
            return {
                loading1: false,
                loading2: false,
                loading3: false,
                preload: false,
                pagination: {},
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    network  : '',
                    from     : null,
                    to       : null
                },
                leader      : [],
                networkNames : [],
                loadingBtn: false,
                leaderSchema: {
                    uuid       : '',
                    networkid  : '',
                    userid     : [],
                    users      : [],
                    excludes   : [],
                    user       : null,
                    rules_json : []
                },
                leaderModel: {},
                leaderMsj  : false,
                leaderColor: '',
                leaderText : '',
                leaderModal: false,
                limit : this.$root.limit,
                offset: 0,
                users1: [],
                search1: null,
                users2: [],
                search2: null,
                users3: [],
                search3: null,
                modelRuleSchema: {
                    network  : {},
                    country  : {},
                    state    : {},
                    city     : {},
                    gender   : {},
                    age      : {},
                    rules    : []
                },
                modelRule: {},
                countries  : [],
                states     : [],
                cities: [],
                leaderUserHeaders: [
                    {
                        text: 'Nombres',
                        align: 'left',
                        value: 'names'
                    },
                    {
                        text: 'Correo eléctronico',
                        align: 'left',
                        value: 'email'
                    },
                    {
                        text: 'Acciones',
                        align: 'left',
                        value: ''
                    }
                ]
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetchLeader()
                },
                deep: true
            },
            profile: {
                handler(){
                    this.fetchNetworkNames()
                },
                deep: true
            },
            search1(val){
                val && this.searchUsers1(val)
            },
            search2(val){
                val && this.searchUsers2(val)
            },
            search3(val){
                val && this.searchUsers3(val)
            }
        },
        created(){

            this.fetchLeader()
            this.leaderModel = Object.assign({}, this.leaderSchema)
            this.filter      = Object.assign({}, this.filterSchema)
            this.modelRule   = this._.cloneDeep(this.modelRuleSchema)
        },
        methods: {
            
            fetchLeader(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.leader = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: JSON.stringify(this.filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/leader', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if (data.response) {
                                let leader = (data.response ? data.data : []).map(l => {
                                    l.users = l.users.map(u => {
                                        u.recursive = u.recursive == 1 ? true : false
                                        return u
                                    })
                                    return l
                                })
                                this.leader = this.leader.concat(leader)
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        } else {
                            let leader = (data.response ? data.data : []).map(l => {
                                l.users = l.users.map(u => {
                                    u.recursive = u.recursive == 1 ? true : false
                                    return u
                                })
                                return l
                            })
                            this.leader = leader
                        }
                    }).catch(() => {})
                })
            },
            infinite(){
                setTimeout(() => this.fetchLeader(true) , 500)
            },
            fetchNetworkNames(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2 || this.$root.profile.type == 1){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url).then((r) => {

                        let data = r.data
                        if(data.response){
                            let networkNames = [[]].concat(data.data) 
                            this.networkNames = networkNames
                        }
                    }).catch(() => {})
                })
            },
            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },
            async open(action, title, data = this.leaderSchema){

                this.titleLeader  = title
                this.updateLeader = (action == 'add' ? false: true)

                this.leaderModel = this._.cloneDeep(this.leaderSchema)
                this.leaderModel = Object.assign({}, this.leaderModel, data)
                this.modelRule   = this._.cloneDeep(this.modelRuleSchema)

                if(this.leaderModel.user != null){
                    this.users1 = [this.leaderModel.user]
                }

                if(this.leaderModel.users.length){
                    this.users2 = this.users2.concat(this.leaderModel.users)
                }

                if(this.leaderModel.excludes.length){
                    this.users3 = this.users3.concat(this.leaderModel.excludes)
                }

                await this.fetchCountries()

                let rules = this.leaderModel.rules_json.map((r => {

                    if('gender' in r){
                        r.gender = this.gender.find(g => g.id == r.gender)
                    }

                    if('age' in r){
                        r.age = this.ages.find(a => a.id == r.age)
                    }

                    return r
                }))

                if(rules.length){
                    this.modelRule.rules = rules
                }

                this.loadingBtn = false
                this.leaderModal  = true
            },
            save(){

                let post = Object.assign({}, this.leaderModel)

                const users = function(user){
                    if(user instanceof Object){
                        return user.uuid
                    }else{
                        return user
                    }
                }

                post.users = JSON.stringify(post.users)

                post.excludes = post.excludes.map(users)
                post.excludes = JSON.stringify(post.excludes)

                let rules = this.modelRule.rules.map((n) => {
                    return {
                        country: !n.hasOwnProperty('country') ? '' : (typeof n.country == 'object' ? (Object.keys(n.country).length ? n.country.uuid : '') : ''),
                        state  : !n.hasOwnProperty('state') ? '' : (typeof n.state == 'object' ? (Object.keys(n.state).length ? n.state.uuid : '') : ''),
                        city   : !n.hasOwnProperty('city') ? '' : (typeof n.city == 'object' ? (Object.keys(n.city).length ? n.city.uuid : '') : ''),
                        gender : !n.hasOwnProperty('gender') ? '' : (typeof n.gender == 'object' ? (Object.keys(n.gender).length ? n.gender.id : '') : ''),
                        age    : !n.hasOwnProperty('age') ? '' : (typeof n.age == 'object' ? (Object.keys(n.age).length ? n.age.id : '') : ''),
                    }
                })

                post.rules_json = JSON.stringify(rules)
                
                let form = new FormData()

                for(let i in post){
                    form.append(i, post[i])
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/leader', form).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.leaderMsj = true

                        if(data.response){
                            this.leaderColor = 'success'
                            this.leaderText = data.message
                            this.leaderModal = false
                            this.fetchLeader()
                        }else{
                            this.leaderColor = 'error'
                            this.leaderText = data.message
                        }
                    }).catch(() => {})
                })
            },
            deleteLeader({uuid}){

                this.$refs.delete.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/leader?uuid=' + uuid).then((r) => {
                        
                        this.$refs.delete.$emit('stopLoader')
                        this.leaderMsj = true

                        let data = r.data

                        if(data.response){
                            this.leaderColor = 'success'
                            this.leaderText = data.message
                            this.$refs.delete.close()
                            this.fetchLeader()
                        }else{
                            this.leaderColor = 'error'
                            this.leaderText = data.message
                        }
                    }).catch(() => {})
                })
            },
            searchUsers1(query){

                this.loading1 = true
                let url = '/network/users'

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            query,
                            networkid: this.leaderModel.networkid
                        }
                    }).then((r) => {

                        this.loading1 = false
                        let data = r.data
                        if(data.response){
                            this.users1 = data.data
                        }
                    }).catch(() => {
                        this.loading1 = false
                    })
                })
            },
            searchUsers2(query){

                this.loading2 = true
                let url = '/network/users'

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            query,
                            networkid: this.leaderModel.networkid
                        }
                    }).then((r) => {

                        this.loading2 = false
                        let data = r.data
                        if (data.response) {
                            let users2 = data.data
                            this.users2 = users2.map(u => {
                                if (!u.hasOwnProperty('recursive')) {
                                    u.recursive = false
                                }
                                return u
                            })
                        }
                    }).catch(() => {
                        this.loading2 = false
                    })
                })
            },
            searchUsers3(query){

                this.loading3 = true
                let url = '/network/users'

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            query,
                            networkid: this.leaderModel.networkid
                        }
                    }).then((r) => {

                        this.loading3 = false
                        let data = r.data
                        if(data.response){
                            this.users3 = data.data
                        }
                    }).catch(() => {
                        this.loading3 = false
                    })
                })
            },
            fetchCountries(){

                this.countries = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/country', {
                            params: {
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.countries = data.data
                            }

                            resolve()

                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            fetchState(){

                this.states = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.modelRule.country.uuid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.states = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            fetchCity(){

                this.cities = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.modelRule.state.uuid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.cities = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            addRule(){

                let emptyProps = 0
                for(let key of Object.keys(this.modelRule)){
                    if(key != 'rules' && !Object.keys(this.modelRule[key]).length){
                        emptyProps++
                    }
                }

                if(emptyProps == Object.keys(this.modelRule).length-1){
                    return
                }

                this.modelRule.rules.push({
                    country: this.modelRule.country,
                    state  : this.modelRule.state,
                    city   : this.modelRule.city,
                    gender : this.modelRule.gender,
                    age    : this.modelRule.age
                })

                let rules      = this.modelRule.rules.slice(0)
                this.modelRule = this._.cloneDeep(this.modelRuleSchema)
                this.modelRule.rules = rules
            }
        }
    }
    
</script>